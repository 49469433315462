// @flow

import React from 'react'
import classNames from 'classnames'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import ScrollAnimation from 'react-animate-on-scroll'
import Tilt from 'react-tilt'

import image01 from '../../../../images/references/previews/teaser-alltours2x.jpg'
import baseStyles from './ReferenceItem.scss'

type Props = {
  ...StyleProps,
  image: Object,
  link?: string,
  delay?: number,
  animatePreScroll?: boolean,
  altText: string
}

const defaultProps = {
  styles: {},
  image: image01,
  delay: 0,
  animatePreScroll: true,
  altText: 'Reference Image'
}

const ReferenceItem = ({ altText, styles, image, link, delay, animatePreScroll }: Props) => (
  <ScrollAnimation
    className={styles.root}
    animateIn="fadeInUp"
    animateOnce
    delay={delay}
    animatePreScroll={animatePreScroll}
  >
    { link ?
      <Tilt className={styles.tilt} options={{ max: 25, glare: true, speed: 1000 }}>
        <a href={link} target="_blank" rel="noopener noreferrer"
          className={classNames(styles.imageWrap, styles.link)}
        >
          <div className={styles.imageContainer}>
            {image}
          </div>
        </a>
      </Tilt> :
      <div className={styles.imageWrap}>
        <div className={styles.imageContainer}>
          {image}
        </div>
      </div>
    }
  </ScrollAnimation>
)

ReferenceItem.defaultProps = defaultProps

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(ReferenceItem)
