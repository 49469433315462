// @flow

import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { Container } from '../../components/basic/Grid'
import ReferenceItem from './Components/ReferenceItem'
import baseStyles from './References.scss'

/* eslint-disable max-len */
const tourtivity = <StaticImage src='../../images/itb2020/tourtivity2x.jpg' alt='' objectFit='contain' className='image' />
const flightfinder = <StaticImage src='../../images/itb2020/flightfinder2x.jpg' alt='' objectFit='contain' className='image' />
const onepager = <StaticImage src='../../images/itb2020/onepager2x.jpg' alt='' objectFit='contain' className='image' />
const futurama = <StaticImage src='../../images/itb2020/futurama2x.jpg' alt='' objectFit='contain' className='image' />

const adminpanel = <StaticImage src='../../images/itb2020/adminpanel2x.jpg' alt='' objectFit='contain' className='image' />
const alternativeInspiration = <StaticImage src='../../images/itb2020/alternative_inspiration2x.jpg' alt='' objectFit='contain' className='image' />
const alternativeInspirationAdmin = <StaticImage src='../../images/itb2020/alternative_inspiration_admin2x.jpg' alt='' objectFit='contain' className='image' />
const freshsocial = <StaticImage src='../../images/itb2020/freshsocial2x.jpg' alt='' objectFit='contain' className='image' />
const freshflix = <StaticImage src='../../images/itb2020/freshflix2x.jpg' alt='' objectFit='contain' className='image' />

const aldiana = <StaticImage src='../../images/itb2020/aldiana2x.jpg' alt='' objectFit='contain' className='image' />
const imagecase = <StaticImage src='../../images/itb2020/imagecase2x.jpg' alt='' objectFit='contain' className='image' />
const tsbov3 = <StaticImage src='../../images/itb2020/tsbov32x.jpg' alt='' objectFit='contain' className='image' />
const ota = <StaticImage src='../../images/itb2020/ota2x.jpg' alt='' objectFit='contain' className='image' />
const otaFreshms = <StaticImage src='../../images/itb2020/ota_freshms2x.jpg' alt='' objectFit='contain' className='image' />
const quickstart = <StaticImage src='../../images/itb2020/quickstart2x.jpg' alt='' objectFit='contain' className='image' />
const fusion = <StaticImage src='../../images/itb2020/fusion2x.jpg' alt='' objectFit='contain' className='image' />
const flightonlytsbo = <StaticImage src='../../images/itb2020/flight_only_tsbo2x.jpg' alt='' objectFit='contain' className='image' />
const smartsuggestion = <StaticImage src='../../images/itb2020/smart_suggestion2x.jpg' alt='' objectFit='contain' className='image' />
const whentotravelwhere = <StaticImage src='../../images/itb2020/when_to_travel_where2x.jpg' alt='' objectFit='contain' className='image' />
const travelcreator = <StaticImage src='../../images/itb2020/travelcreator2x.jpg' alt='' objectFit='contain' className='image' />
const inspirationalsearch = <StaticImage src='../../images/itb2020/inspirational_search2x.jpg' alt='' objectFit='contain' className='image' />

import logoInnovationLabSrc from '../../images/logo/innovation-lab-color.svg'
const logoInnovationLab = <img src={logoInnovationLabSrc} alt='' className='image' />
/* eslint-enable max-len */


type Props = {
  ...StyleProps,
}

const References = ({ styles }: Props) => (
  <Container className={styles.root} fluid>
    <div className={styles.previewList}>
      <ReferenceItem
        styles={styles.item}
        image={fusion}
        // eslint-disable-next-line max-len
        link='https://www.figma.com/proto/jWtCNXPcAY1H4IR18lIuSd/Fusion-Admin-Panel?node-id=19584%3A41&viewport=439%2C63%2C0.125&scaling=min-zoom'
        altText='Fusion Admin Panel'
      />
      <ReferenceItem
        styles={styles.item}
        image={quickstart}
        link='http://tsbodemo:tsbodemo2019@stage-tsbo.tsbonext.k8s.ext.fcse.io/'
        altText='Quickstart'
      />
      <ReferenceItem
        styles={styles.item}
        image={aldiana}
        link='https://thomascook:freshcook@tsboshow-demo-tchr.lb.fcse.io/de-de'
        altText='Aldiana'
      />
      <ReferenceItem
        styles={styles.item}
        image={otaFreshms}
        link='https://potauser:demoshowcase@edit.demo.ota.k8s.ext.fcse.io'
        altText='OTA solution freshms'
      />
      <ReferenceItem
        styles={styles.item}
        image={ota}
        link='https://potauser:demoshowcase@demo.ota.k8s.ext.fcse.io/'
        altText='OTA solution'
      />
      <ReferenceItem
        styles={styles.item}
        image={adminpanel}
        link='https://tsbo-stage-tsbo.lb.fcse.io/admin/FcseFeatureSettingsManagementBundle'
        altText='AdminPanel'
      />
      <ReferenceItem
        styles={styles.item}
        image={tsbov3}
        link='https://tsbo-stage-tsbo.lb.fcse.io/de-de'
        altText='TSBO3'
      />
      <ReferenceItem
        styles={styles.item}
        image={imagecase}
        link='http://freshcells:fair,2019@sbo.stage.fvw.ext.fcse.io/'
        altText='ImageCase'
      />
      <ReferenceItem
        styles={styles.item}
        image={flightonlytsbo}
        // eslint-disable-next-line max-len
        link='https://www.figma.com/proto/9LJtxwvFcjl4YACtETQ0AP/Flight-only-TSBO?node-id=563%3A191965&viewport=5851%2C4063%2C0.597520112991333&scaling=min-zoom'
        altText='Flight only TSBO'
      />
    </div>
    <h2 className={styles.subheadline} >
      {logoInnovationLab} by freshcells
    </h2>
    <div className={styles.previewList}>
      <ReferenceItem
        styles={styles.item}
        image={tourtivity}
        link='http://axis:tourtivity2019@stage.tourtivity.k8s.ext.fcse.io'
        altText='Tourtivity'
      />
      <ReferenceItem
        styles={styles.item}
        image={flightfinder}
        link='http://flightfinder.fair.fcse.io/'
        altText='Flight Finder'
      />
      <ReferenceItem
        styles={styles.item}
        image={futurama}
        link='http://freshcells:fair,2019@futurama.fvw.ext.fcse.io/#/?_k=63mn7q'
        altText='Futurama'
      />
      <ReferenceItem
        styles={styles.item}
        image={freshsocial}
        // eslint-disable-next-line max-len
        link='http://freshcells:fair,2019œstage.sbo.fair.fcse.io/de/spanien/mallorca/playa-de-murohsm-lago-park-apartamentos-15858'
        altText='freshSocial'
      />
      <ReferenceItem
        styles={styles.item}
        image={freshflix}
        link='https://default-ext-freshflix.lb.fcse.io/de-de'
        altText='Freshflix'
      />
      <ReferenceItem
        styles={styles.item}
        image={alternativeInspiration}
        link='https://fvw1-stage-tsbo-ext.fcse.io/en-US/alternative'
        altText='Futurama'
      />
      <ReferenceItem
        styles={styles.item}
        image={alternativeInspirationAdmin}
        link='https://fvw1-stage-tsbo-ext.fcse.io/admin/FcseFeatureSettingsManagementBundle'
        altText='Futurama'
      />
      <ReferenceItem
        styles={styles.item}
        image={onepager}
        link='http://freshcells:fair,2019@onepager.fvw.ext.fcse.io/'
        altText='TSBO OnePager'
      />
      <ReferenceItem
        styles={styles.item}
        image={smartsuggestion}
        // eslint-disable-next-line max-len
        link='https://www.figma.com/proto/KM1zxHDjFJ8BpLG8BRPK8V/ITB20-%E2%80%93-Brainstorming-Showcases?node-id=129%3A47&viewport=495%2C380%2C0.05510062724351883&scaling=min-zoom'
        altText='Smart suggestion'
      />
      <ReferenceItem
        styles={styles.item}
        image={inspirationalsearch}
        // eslint-disable-next-line max-len
        link='https://www.figma.com/proto/KM1zxHDjFJ8BpLG8BRPK8V/ITB20-%E2%80%93-Brainstorming-Showcases?node-id=4%3A57201&viewport=2001%2C840%2C0.25843650102615356&scaling=min-zoom'
        altText='Inspirational Search'
      />
      <ReferenceItem
        styles={styles.item}
        image={whentotravelwhere}
        // eslint-disable-next-line max-len
        link='https://www.figma.com/proto/KM1zxHDjFJ8BpLG8BRPK8V/ITB20-%E2%80%93-Brainstorming-Showcases?node-id=291%3A258933&viewport=587%2C1475%2C0.35417869687080383&scaling=min-zoom'
        altText='When to travel where'
      />
      <ReferenceItem
        styles={styles.item}
        image={travelcreator}
        // eslint-disable-next-line max-len
        link='https://www.figma.com/proto/KM1zxHDjFJ8BpLG8BRPK8V/ITB20-%E2%80%93-Brainstorming-Showcases?node-id=358%3A1&viewport=1092%2C746%2C0.16125480830669403&scaling=min-zoom'
        altText='Travelcreator'
      />
    </div>
  </Container>
)

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(References)
