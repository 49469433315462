// @flow

import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import Layout from '../components/Layout/index'
import Header from '../modules/Header'
import Footer from '../modules/Footer'
import SubpageHeroTeaser from '../components/SubpageHeroTeaser'
import ClientOverview from '../components/Clients/ClientOverview'
import References from '../components/References/index-itb.js'
import Products from '../components/Products'
import SEO from '../components/seo'
import ScrollAnimation from 'react-animate-on-scroll'
import CaseTeasers from '../components/CaseStudy/CaseTeasers'

import metaDescription from '../messages/pageMetaDescriptions.lang'
import baseStyles from './references.scss'

const imageDesktop = (
  <StaticImage
    src="../images/heroteaser/references_heroteaser_bg_desktop.jpg"
    alt=""
    objectFit="cover"
    className="image"
  />
)

type Props = {
  ...StyleProps
}

const IndexPage = ({ styles }: Props) => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout footer={<Footer />} header={<Header withTeaser />}>
      <SEO title="ITB2020" description={formatMessage(metaDescription.referencesDescription)} lang={locale} />
      <SubpageHeroTeaser title="Showcases ITB 2020" image={imageDesktop} />
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={true} animateOnce>
        <Products className={styles.products} />
      </ScrollAnimation>
      <References />
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <ClientOverview />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <CaseTeasers list={['lhh', 'plattenplaner', 'aldiana', 'etravel', 'weekend', 'bedfinder']} allButton />
      </ScrollAnimation>
    </Layout>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(IndexPage)
